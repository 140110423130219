<template>
    <button
            :disabled="disabled"
            :class="buttonClass"
            :style="buttonStyles"
            :type="buttonType"
            :onclick="buttonOnClick"
            >

            <spinner v-if="loading"
                     overlay="absolute"
                     :overlayOpacity="0.15"
                     :inline="true"
                     uk-scrollspy="cls: uk-animation-fade;"
                     :size="spinnerSize"></spinner>


        <span class="button-inner">

            <span v-if="loading" class="height-keeper" >.</span>
            <span class="button-icon button-icon--start" :uk-icon="iconProps" v-if="icon"></span>
            <!-- @slot button content. Icon may appear before or after it-->
            <span class="button-text" v-if="showSlot"><slot>{{$t('core.buttonTextDefault')}}</slot></span>
            <span class="button-icon button-icon--end" :uk-icon="iconEndProps" v-if="iconEnd"></span>
        </span>


    </button>
</template>

<script>
    export default {
        props : {
            /**
             * Button primary type. Primary style of button.
             * @see https://getuikit.com/docs/button
             * @values default, primary, secondary, danger, text, link
             */
            type: {
                type: String,
                default: 'primary',
            },
            /**
             * Size modifier for button
             * Values other than specified are ignored
             * @values small, large
             */
            size: {
                type: String,
                default: 'medium',
            },
            /**
             * Is this loading right now?
             * If so, a spinner is displayed instead of the button content
             */
            loading: {
                type: [Boolean],
                default: false,
            },
            /**
             * Force icon ratio.
             * Number or number like string for setting the ratio
             * false for allowing component to set the ratio
             **/
            iconRatio: {
                type: [String, Number, Boolean],
                default: false,
            },
            /**
             * Icon for the button
             * Used as class name, works with any icon in the framework
             */
            icon : {
                type: [Boolean, String],
                default: false,
            },
            /**
             * Icon to be position after the slot
             **/
            iconEnd : {
                type: [Boolean, String],
                default: false,
            },
            /**
             * Is the button disabled
             **/
            disabled : {
                type: Boolean,
                default: false
            },
            /**
             * Should the button be full width
             */
            fullWidth: {
                type: Boolean,
                default: false
            },
            /**
             *  Should the slot be rendered (use false if you dont want content in the button)
             */
            showSlot: {
                type: Boolean,
                default: true
            },
            /**
             * Prop to catch class assignment, we will manage it ourselves with a Computed
             */
            class : {
                type: [String],
                default: '',
            },
            /**
             * When true, will limit side padding
             */
            slim : {
                type: Boolean,
                default: false
            },
            /**
             * Button behaviour, calculated into the "type" attribute or other things
             * Special values: "none" for no actions and "custom" for custom events - todo
             */
            behaviour: {
                type: String,
                default: 'submit',
            }
        },
        data: function () {
            return {};
        },
        methods : {

        },
        mounted : function () {

        },
        computed: {
            buttonAttributes () {
                let buttonAttributes  = {...this.$.attrs };
                delete buttonAttributes.class;
                return buttonAttributes;
            },
            buttonClass () {
                let className = (this.$.attrs.class || '').split(' ');

                className.push('uk-button');

                // implement button type that modifies appearance
                if (['primary','secondary','danger','link','text'].includes(this.type)) {
                    className.push('uk-button-'+this.type);
                }

                // implement other button types
                if (['reset', 'default'].includes(this.type)) {
                    className.push('uk-button-default');
                }

                // implement button size
                if (['small','large', 'medium'].includes(this.size)) {
                    className.push('uk-button-'+this.size);
                }

                // implement button loading class
                if (this.loading) {
                    className.push('uk-button-'+'loading');
                }

                if (this.fullWidth) {
                    className.push('uk-width-1-1');
                }

                className.push(this.class);

                return className;
            },
            buttonStyles() {
              let styles = {};

              if (this.slim) {
                  styles['padding-left'] = '10px';
                  styles['padding-right'] = '10px';
              }

              return styles;
            },
            buttonType () {
                // todo: look at this, probably should be removed
                if (['submit', 'reset'].includes(this.type)) {
                    return this.type
                }

                if (['submit', 'reset'].includes(this.behaviour)) {
                    return this.behaviour
                }

                return  '';
            },
            hasIcon () {
                return this.icon != '';
            },
            hasIconStart () {
                return this.hasIcon && this.iconPosition !== 'end'
            },
            spinnerSize () {
                if (this.size === 'large') {
                    return  1.3;
                }

                if (this.size === 'small') {
                    return  0.45;
                }

                return 0.7;
            },
            iconProps () {
                let result = `icon: ${this.icon}; `;


                if (this.iconRatio) {
                    result = result + 'ratio: ' + this.iconRatio + ';';
                }

                if ( ! this.iconRatio && this.size == 'small') {
                    result = result + 'ratio: 0.8;'
                }

                return result;
            },
            iconEndProps () {
                let result = `icon: ${this.iconEnd}; `;
                if (this.iconRatio) {
                    result = result + 'ratio: ' + this.iconRatio + ';';
                }

                if ( ! this.iconRatio && this.size == 'small') {
                    result = result + 'ratio: 0.8;'
                }

                return result;
            },
            buttonOnClick() {
                if (this.behaviour === 'none') {
                    return function(e){e.preventDefault()}
                } else {
                    return function(e) {};
                }
            },

        },
        watch: {

        }

    }
</script>

<style scoped lang="scss">

    .uk-button {
        position: relative;

        .button-icon--start {
            display: inline-block;
            margin-inline-end: 2px;
        }

        .button-icon--end {
            display: inline-block;
            margin-inline-start: 2px;
        }
    }

    .uk-button:not(.uk-button-small) .button-inner {
        display: flex;
        align-items: center;
        justify-content: center;

        .height-keeper {
            width: 0;
            pointer-events:none;
            overflow:hidden;
            color: transparent
        }
    }

    .uk-button.uk-button-small {
        height: calc(var(--global-margin) * 1.5);

        .button-inner {
            height: calc(var(--global-margin) * 1.5);
        }

    }

    .uk-button.uk-button-medium {
        height: calc(var(--global-margin) * 2 );

        .button-inner {
            height: calc(var(--global-margin) * 1.5);
        }

    }

    .uk-button.uk-button-large {
        height: calc(var(--global-margin) * 2.6 + 3px);

        .button-inner {
            height: calc(var(--global-margin) * 2.6 + 3px);
        }
    }

    .uk-button.uk-font-bold {
       .button-text{
           position: relative;
           top:  calc(var(--global-margin) * -0.1);
       }
    }




    .uk-button.uk-button-loading .button-inner {
        .button-text, .button-icon {
            opacity: 0;
            color: transparent;
            pointer-events: none;
            visibility: hidden;


        }
    }

    .uk-button.uk-button-loading, .uk-button.uk-button-loading[disabled] {
        cursor: wait;
    }

    .uk-button[disabled] {
        cursor: not-allowed;
    }



    .button-text {
        white-space: nowrap;
    }

</style>
